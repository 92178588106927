import React from 'react';
import './Character.css';

const Character = () => {
      return (
    <div className="character-tab">
      <div className="character-sheet-content">
        <p>Click the Character tab to open your character sheet in a new window.</p>
      </div>
    </div>
  );
};

export default Character; 